import { useEffect, useRef, useState } from "react";
import "../style/css/Lessons.css";
import useAutosizeTextArea from "./util/InputUtil";

import axios from "axios";
import { API_URL } from "./util/ApiUtil";
import $ from "jquery";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setPreference } from "../features/preference/preferenceSlice";
import { setLessonCache } from "../features/LessonCache/lessonCache";
import { logout } from "../features/auth/authSlice";
import { hideProgressbar, showProgressbar } from "./ProgressbarHelper";
const API_HEADER_CONFIG = {
	headers: {
		"Content-Type": "multipart/form-data",
	},
};
const Lessons = () => {
	const dispatch = useDispatch();
	const [lessonTitle, setLessonTitle] = useState("");
	const [lessonContent, setLessonContent] = useState("");
	const [thumbnailUrl, setThumbnailUrl] = useState("");
	const textAreaRef = useRef(null);
	const titleRef = useRef(null);
	const [isAdmin, setIsAdmin] = useState(false);
	const { lessonCache } = useSelector((state) => state.lessonCache);
	let { preference } = useSelector((state) => state.preference);
	const recentRefreshTimestamp = preference.refreshTimestamp;

	const { user } = useSelector((state) => state.auth);
	const API_HEADER_CONFIG1 = {
		headers: {
			Authorization: `Bearer ${user ? user.token : ""}`,
			"Content-Type": "multipart/form-data",
		},
	};
	const API_HEADER_CONFIG2 = {
		headers: {
			Authorization: `Bearer ${user ? user.token : ""}`,
		},
	};
	useEffect(() => {
		if (user != null) {
			setIsAdmin(true);
			checkSessionValidity(user.token);
		} else {
			setIsAdmin(false);
		}
	}, [user, dispatch]);

	const submitLesson = async (e) => {
		e.preventDefault();
		let thumbnail = $("#thumbnail-input").prop("files")[0];
		if (lessonTitle.trim() === "") {
			alert("Lesson title should not be empty");
			return;
		}
		if (lessonContent.trim() === "") {
			alert("Lesson content should not be empty");
			return;
		}
		if (!thumbnail) {
			alert("Please choose a thumbnail!");
			return;
		}
		const formData = new FormData();
		formData.append("content", lessonContent.trim());
		formData.append("thumbnail", thumbnail, lessonTitle.trim());
		formData.append("title", lessonTitle.trim());
		try {
			showProgressbar(".lessonSubmitProgressbar", "#lesson-publish-button");
			const response = await axios.post(
				API_URL + "lesson/add",
				formData,
				API_HEADER_CONFIG1
			);
			if (!response) {
				throw {
					response: {
						data: {
							message: "Something went wrong while uploading lesson",
						},
					},
				};
			}
			setLessonTitle("");
			setLessonContent("");
			setThumbnailUrl("");
			$("#thumbnail-input").val("");

			submitRefreshRequirement();
		} catch (error) {
			if (error.response.data.message === "sessionexpired") {
				alert("Session expired! please login again");
				dispatch(logout());
			} else {
				alert(error.response.data.message);
			}
		}
	};

	const checkSessionValidity = async (token) => {
		let data = {
			token: token,
		};
		try {
			const response = await axios.post(API_URL + "session/check", data);
			if (!response) {
				throw {
					response: {
						data: "",
					},
				};
			}
			if (!response.data.isSessionValid) {
				dispatch(logout());
			}
		} catch (error) {
			console.log(error.response.data);
		}
	};
	const checkRefreshRequirement = async () => {
		let data = {
			timestamp: recentRefreshTimestamp,
		};
		try {
			const response = await axios.post(API_URL + "refreshRequirement", data);
			if (!response) {
				throw "";
			}
			if (response.data || recentRefreshTimestamp == -1) {
				getLessons();
			}
		} catch (error) {
			console.log(
				"Error: Something went wrong while checking refresh requirement."
			);
		}
	};
	useEffect(() => {
		checkRefreshRequirement();
	}, [API_HEADER_CONFIG]);

	const getLessons = async () => {
		try {
			const response = await axios.post(API_URL + "lesson/get");
			if (!response) {
				throw {
					message: "Something went wrong while geting lessons",
				};
			}
			let array = [];
			response.data.snapshot.forEach((item) => {
				array.push(item);
			});
			dispatch(setLessonCache(array));
			let preferenceData = {
				refreshTimestamp: Date.now(),
			};
			dispatch(setPreference(preferenceData));
		} catch (error) {
			console.log(error);
		}
	};

	useAutosizeTextArea(textAreaRef.current, lessonContent);
	useAutosizeTextArea(titleRef.current, lessonTitle);

	const submitRefreshRequirement = async () => {
		try {
			const response = await axios.post(
				API_URL + "setRefreshRequirement",
				{},
				API_HEADER_CONFIG2
			);
			if (!response) {
				throw {
					response: {
						data: {
							message: "Something went wrong!",
						},
					},
				};
			}
			alert("Lesson submitted successfully");
			hideProgressbar(".lessonSubmitProgressbar", "#lesson-publish-button");
			checkRefreshRequirement();
		} catch (error) {
			hideProgressbar(".lessonSubmitProgressbar", "#lesson-publish-button");
			alert(error.response.data.message);
		}
	};
	return (
		<div id="lessons-container">
			{isAdmin && (
				<div id="lesson-form-container">
					<div id="lesson-form-left">
						<div className="input-field">
							<textarea
								value={lessonTitle}
								onChange={(e) => setLessonTitle(e.target.value)}
								ref={titleRef}
								rows={1}
								id="lesson-title-input"
								placeholder="Write title of the lesson here"
							></textarea>
						</div>
						<div className="input-field">
							<textarea
								value={lessonContent}
								onChange={(e) => setLessonContent(e.target.value)}
								ref={textAreaRef}
								rows={1}
								id="lesson-content-input"
								placeholder="Write content of the lesson here"
							></textarea>
						</div>
					</div>
					<div id="lesson-form-right">
						<label htmlFor="thumbnail-input">
							<span id="thumbanail-placeholder">Choose thumbnail</span>
							<span
								style={{
									backgroundImage: `url(${thumbnailUrl})`,
								}}
								id="thumbnail-preview"
							></span>
						</label>
						<input
							id="thumbnail-input"
							type="file"
							onChange={(e) => {
								if (e.target.files != null) {
									setThumbnailUrl(URL.createObjectURL(e.target.files[0]));
								}
							}}
						/>
						<button id="lesson-publish-button" onClick={submitLesson}>
							Publish
						</button>
						<div className="progressbar lessonSubmitProgressbar"></div>
					</div>
				</div>
			)}
			<div id="articles-container">
				{lessonCache.map((lesson, index) => {
					return (
						<div key={index} className="article">
							<div
								className="article-graphic"
								style={{
									backgroundImage: `url(${lesson.thumbnail})`,
								}}
							>
								<div></div>
							</div>
							<h3>{lesson.title}</h3>
							<p>{lesson.content.substring(0, 200) + "..."}</p>
							<Link to={"../read/" + lesson.uid}>Read more</Link>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default Lessons;
