import { Link } from "react-router-dom";
import "../style/css/Collaboration.css";
import Logo from "./util/Logo";

const Collaboration = () => {
	return (
		<div id="collaboration-container">
			<h2 id="collaboration-label">
				Collaboration with <br /> Schools and Colleges
			</h2>
			<div id="collaboration-info">
				<p>
					English Buzz also offers training sessions of Spoken English
					and personality development at schools, colleges, companies
					and corporate offices.
				</p>
				<Link to="contact">
					INVITE US FOR FREE WORKSHOP AND SEMINAR
				</Link>
				{/* <Link id="gallery-button" to="gallery">
					See Gallery
				</Link> */}
			</div>
			<div id="collaboration-clients">
				<div>
					<Logo />
				</div>
				<div>
					<Logo />
				</div>
				<div>
					<Logo />
				</div>
				<div>
					<Logo />
				</div>
				<div>
					<Logo />
				</div>
				<div>
					<Logo />
				</div>
				<div>
					<Logo />
				</div>
				<div>
					<Logo />
				</div>
				<div>
					<Logo />
				</div>
				<div>
					<Logo />
				</div>
			</div>
		</div>
	);
};

export default Collaboration;
