import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router";
import Layout from "./components/Layout";
import "./style/css/Main.css";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import CoursePage from "./components/CoursePage";
import TrainerPage from "./components/TrainerPage";
import GalleryPage from "./components/GalleryPage";
import Lessons from "./components/Lessons";
import ReadLesson from "./components/ReadLesson";

import { store } from "./app/store";
import { Provider } from "react-redux";
import Wrapper from "./components/util/ScrollToTop";

const root = ReactDOM.createRoot(
	document.getElementById("website-container") as HTMLElement
);
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Provider store={store}>
				<Wrapper>
					<Routes>
						<Route path="/" element={<Layout />}>
							<Route index element={<Home />} />
							{/* <Route path="courses" element={<CoursePage />} /> */}
							<Route path="trainers" element={<TrainerPage />} />
							{/* <Route path="collaborate" element={<About />} /> */}
							<Route path="about" element={<About />} />
							<Route path="contact" element={<Contact />} />
							{/* <Route path="join" element={<About />} /> */}
							{/* <Route path="gallery" element={<GalleryPage />} /> */}
							<Route path="lessons" element={<Lessons />} />
							<Route path="read/:id" element={<ReadLesson />} />
						</Route>
					</Routes>
				</Wrapper>
			</Provider>
		</BrowserRouter>
	</React.StrictMode>
);
reportWebVitals();
