import "../style/css/TrainerPage.css";
import Veeru from "../img/veeru.jpeg";
import Harsh from "../img/harsh.jpg";
import Shashwat from "../img/shashwat.jpg";
const TrainerPage = () => {
	return (
		<div id="trainer-page-container">
			<div className="trainer-section">
				<h2 className="trainer-page-label">Team English Buzz</h2>
				<div className="primary-divider">
					<div className="primary-divider-wrapper"></div>
				</div>
				<div className="trainer-wrapper">
					<div
						className="trainer-image"
						style={{ backgroundImage: `url(${Harsh})` }}
					></div>
					<h3>
						Harsh <br />
						<span>Founder & Thinker</span>
					</h3>
					<p>
						A Mechanical Engineer from IIT Kanpur, is also a teacher of
						Mathematics. He has taught communication, public speaking &
						personality development for more than four years. He has vast
						teaching experience at Allen, Vedantu, Motion, and various
						Engineering colleges in Jaipur. He loves listening to music and
						traveling.
					</p>
				</div>

				<div className="trainer-wrapper">
					<div
						className="trainer-image"
						style={{ backgroundImage: `url(${Veeru})` }}
					></div>
					<h3>
						Veeru <br />
						<span>Co-Founder & Creator</span>
					</h3>
					<p>
						Veeru is a communication enthusiast. He has a master's in English
						literature and a diploma in PGDT. He has been teaching communication
						for the last six years and is a renowned faculty in Jaipur. He loves
						interacting with new people and inspires students to learn English
						with his amicable personality.
					</p>
				</div>
			</div>
			<div className="trainer-section  tw-below">
				{/* <h2 className="trainer-page-label">Our trainers</h2>
				<div className="primary-divider">
					<div className="primary-divider-wrapper"></div>
				</div> */}

				<div className="trainer-wrapper">
					<div
						className="trainer-image"
						style={{ backgroundImage: `url(${Shashwat})` }}
					></div>
					<h3>Shashwat</h3>
					<p>
						Shashwat is a softskill and personality development trainer. He has
						an experience of 4 years as a softskill trainer in various companies
						like Teleperformance, CarDekho and Jumio. He is passionate about
						teaching and interacting with people from different fields.
					</p>
				</div>
			</div>
		</div>
	);
};

export default TrainerPage;
