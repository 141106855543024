import { Link } from "react-router-dom";
import "../style/css/Courses.css";
const Statistics = () => {
	return (
		<div id="courses-container">
			<h2 id="courses-label">Find The Best Course For You</h2>

			{/* <p>
				<span>•</span>Spoken English
			</p>
			<p>
				<span>•</span>Public Speaking
			</p>
			<p>
				<span>•</span>Personality Development
			</p>
			<p>
				<span>•</span>Interview Training
			</p>
			<p>
				<span>•</span>GD & Debating Skills
			</p>
			<p>
				<span>•</span>Fluency and Confidence builidng
			</p>
			<p>
				<span>•</span>Body Language
			</p>
			<p>
				<span>•</span>Leadership Skills
			</p> */}

			<div className="course">
				<h2>Online</h2>
				<h3>@ ₹999</h3>
				<h4>WHAT'S INCLUDED ——</h4>
				<p>Daily one hour live class</p>
				<p>Practice sessions on Whatsapp</p>
				<p>Interview training</p>
				<p>Spoken English</p>
				<p>Public speaking</p>
				<p>Personality development</p>
				<p>Leadership skills</p>
				<Link to={"contact"}>
					Contact Now <span>➡</span>
				</Link>
			</div>
			<div className="course">
				<h2>Offline</h2>
				<h3>
					Call for details <br />
					7014578489
				</h3>
				<h4>WHAT'S INCLUDED ——</h4>
				<p>Body language</p>
				<p>Public speaking</p>
				<p>Confidence building</p>
				<p>Podium presentation</p>
				<p>Group discussion</p>
				<p>Interview training</p>
				<p>Personality development</p>
				<p>Fluency in English</p>
				<p>Team management</p>
				<Link to={"contact"}>
					Contact Now <span>➡</span>
				</Link>
			</div>
		</div>
	);
};

export default Statistics;
