import axios from "axios";
import "../style/css/ContactForm.css";
import { hideProgressbar, showProgressbar } from "./ProgressbarHelper";
import { API_URL } from "./util/ApiUtil";
import { useState } from "react";
import Sms from "../img/sms.png";
import Email from "../img/email.png";
import Call from "../img/dial.png";

const ContactForm = () => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [number, setNumber] = useState("");
	const [message, setMessage] = useState("");
	const sendMessage = async () => {
		if (name.trim() === "") {
			alert("Name is compulsory");
			return;
		}
		if (email.trim() === "") {
			alert("Email is compulsory");
			return;
		}
		if (number.trim() === "") {
			alert("Number is compulsory");
			return;
		}
		if (message.trim() === "") {
			alert("Message is compulsory");
			return;
		}
		const data = {
			name: name,
			email: email,
			number: number,
			message: message,
		};
		try {
			showProgressbar(".messageSubmitProgressbar", "#submit-button");
			let response = await axios.post(API_URL + "contact", data);
			hideProgressbar(".messageSubmitProgressbar", "#submit-button");
			if (!response) {
				throw "Something went wrong!";
			}
			alert(response.data);
		} catch (error) {
			hideProgressbar(".messageSubmitProgressbar", "#submit-button");
			console.log(error);
			alert("Please enter correct input and try again");
		}
	};
	return (
		<div id="contact-form">
			<div id="contact-form-label-wrapper">
				<h2 id="contact-section-label">We'd love to hear from you.</h2>
				<p id="contact-section-email-label">
					You can reach us anytime via <span>englishbuzz21@gmail.com</span>
				</p>
				<div id="contact-form-action-button-container">
					<a
						href="mailto:englishbuzz21@gmail.com?subject=Regarding English Buzz"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={Email} alt="Email logo" />
					</a>
					<a href="sms:7014578489" target="_blank" rel="noopener noreferrer">
						<img src={Sms} alt="Sms logo" />
					</a>
					<a href="tel:7014578489" target="_blank" rel="noopener noreferrer">
						<img src={Call} alt="Dial logo" />
					</a>
				</div>
			</div>
			<div className="input-field">
				<label htmlFor="name-input">Name</label>
				<input
					value={name}
					onChange={(e) => setName(e.target.value)}
					type="text"
					id="name-input"
					placeholder="Your name"
					maxLength={30}
				/>
			</div>
			<div className="input-field">
				<label htmlFor="email-input">Email</label>
				<input
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					type="email"
					id="email-input"
					placeholder="you@example.com"
					maxLength={50}
				/>
			</div>
			<div className="input-field">
				<label htmlFor="number-input">Phone number</label>
				<input
					value={number}
					onChange={(e) => setNumber(e.target.value)}
					type="number"
					id="number-input"
					placeholder=" +91 00000 00000"
				/>
			</div>
			<div className="input-field">
				<label htmlFor="desc-input">How can we help you?</label>
				<textarea
					value={message}
					onChange={(e) => setMessage(e.target.value)}
					id="desc-input"
					placeholder="Say Hello 👋! to us"
					maxLength={300}
				></textarea>
			</div>
			<div className="input-field">
				<div className="progressbar messageSubmitProgressbar"></div>

				<button onClick={sendMessage} id="submit-button">
					Send
				</button>
			</div>
		</div>
	);
};

export default ContactForm;
