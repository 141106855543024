import "../style/css/Header.css";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import Logo from "../img/logo.jpg";
import Menu from "../img/menu.png";
import Footer from "./Footer";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login, logout, reset } from "../features/auth/authSlice";
import { hideProgressbar, showProgressbar } from "./ProgressbarHelper";
import $ from "jquery";
import axios from "axios";
import { API_URL } from "./util/ApiUtil";
import ContactForm from "./ContactForm";
import WhatsappLogo from "../img/whatsapp_logo.png";

const Layout = () => {
	const dispatch = useDispatch();
	const [password, setPassword] = useState("");
	const { user, isLoading, isError, isSuccess, message } = useSelector(
		(state) => state.auth
	);
	const [isAdmin, setIsAdmin] = useState(false);

	const onSubmit = (e) => {
		e.preventDefault();
		if (password === "") {
			return;
		}
		const userData = {
			password,
		};
		dispatch(login(userData));
	};
	const location = useLocation();

	useEffect(() => {
		$("#mobile-navigation-container").hide();
	}, [location]);
	useEffect(() => {
		setTimeout(() => {
			$("#contact-popup-container").css("display", "flex");
		}, 5000);
		if (isError) {
			showError(message);
		}
		if (isSuccess || user) {
			hideError();
			setIsAdmin(true);
			$("#login-popup").hide();
			$("#password").val("");
		} else {
			setIsAdmin(false);
		}
		if (isLoading) {
			showProgressbar(".submitProgressbar", "#submit-button");
		} else {
			hideProgressbar(".submitProgressbar", "#submit-button");
		}
		dispatch(reset());
	}, [user, isLoading, isError, isSuccess, message, dispatch]);

	const showError = (message) => {
		$("#error-text").show();
		$("#error-text").text(message);
	};
	const hideError = () => {
		$("#error-text").hide();
	};

	const showNav = () => {
		$("#mobile-navigation-container").toggle();
	};

	const closePopup = () => {
		$("#contact-popup-container").hide();
	};

	return (
		<div id="root">
			<div id="contact-popup-container">
				<div id="contact-popup">
					<p onClick={closePopup} id="contact-popup-close-button">
						✕
					</p>
					<ContactForm />
				</div>
			</div>
			<div id="login-popup">
				<div id="login-form">
					<p id="error-text"></p>
					<input
						onChange={(e) => setPassword(e.target.value)}
						value={password}
						type="password"
						id="password"
					/>
					<button onClick={onSubmit} id="submit-button">
						Submit
					</button>
					<div className="progressbar submitProgressbar"></div>
				</div>
			</div>
			<a
				href="https://wa.me/7014578489"
				class="whatsapp_float"
				target="_blank"
				rel="noopener noreferrer"
			>
				<img src={WhatsappLogo} alt="Whatsapp logo" />
			</a>
			<div id="mobile-navigation-container">
				<div>
					<NavLink className="nav-link" to="/">
						Home
					</NavLink>
					{/* <NavLink className="nav-link" to="courses">
							Courses
						</NavLink> */}
					<NavLink className="nav-link" to="trainers">
						Our Team
					</NavLink>
					<NavLink className="nav-link" to="lessons">
						Lessons
					</NavLink>
					{/* <NavLink className="nav-link" to="collaborate">
							Collaborate
						</NavLink> */}
					{/* <NavLink className="nav-link" to="gallery">
							Gallery
						</NavLink> */}
					<NavLink className="nav-link" to="about">
						About Us
					</NavLink>
					<NavLink to="contact" className="header-primary-button">
						Contact Us
					</NavLink>
				</div>
			</div>
			<div className="component-container" id="header-container">
				{/* <div id="mini-header">
					<p>ENGLISH BUZZ Coaching Classes</p>
					<p>Sector 11, Pratap Nagar, Jaipur, Rajasthan 302011</p>
					<p>emailaddress@gmail.com</p>
					<p>Mobile: 070145 78489</p>
					<p>Office: 076150 78055</p>
				</div> */}

				{/* <div className="responsive-width-limiter"> */}

				<div id="header">
					<div
						id="logo-wrapper"
						onDoubleClick={() => {
							if (isAdmin) dispatch(logout());
							else $("#login-popup").css("display", "flex");
						}}
					>
						<img src={Logo} alt="" />
					</div>
					<div id="navigation-container">
						<NavLink className="nav-link" to="/">
							Home
						</NavLink>
						{/* <NavLink className="nav-link" to="courses">
							Courses
						</NavLink> */}
						<NavLink className="nav-link" to="trainers">
							Our Team
						</NavLink>
						<NavLink className="nav-link" to="lessons">
							Lessons
						</NavLink>
						{/* <NavLink className="nav-link" to="collaborate">
							Collaborate
						</NavLink> */}
						{/* <NavLink className="nav-link" to="gallery">
							Gallery
						</NavLink> */}
						<NavLink className="nav-link" to="about">
							About Us
						</NavLink>
					</div>
					<NavLink to="contact" className="header-primary-button">
						Contact Us
					</NavLink>

					<div id="mobile-nav-button-container">
						<img onClick={showNav} id="mobile-nav-button" src={Menu} alt="" />
					</div>
				</div>
				{/* </div> */}
			</div>
			<div className="component-container" id="website-body-container">
				{/* <div className="responsive-width-limiter"> */}
				<Outlet />
				<Footer />
				{/* </div> */}
			</div>
		</div>
	);
};

export default Layout;
