import "../style/css/WhyBest.css";
import Creative from "../img/interactive.png";
import Study from "../img/study.png";
const WhyBest = () => {
	return (
		<div id="why-best-container">
			<h2>Why We Are Best?</h2>
			<div id="best-card-wrapper">
				<div className="best-card">
					<img src={Creative} alt="" />
					<h3>Highly creative and Interactive classes</h3>
					<p>
						Learn to speak English through innovative activities like role
						plays, pictorial presentations, movie reviews, vocabulary games,
						debates, English songs, group discussions and many more...
					</p>
				</div>

				<div className="best-card">
					<img src={Study} alt="" />
					<h3>Learn English without getting entangled in grammer rules</h3>
					<p>
						We believe that a language can be learnt without the rules of
						grammer. We can learn it through observations, listening,
						immitations and practices. <br /> <br /> जैसे आप हिंदी बिना grammer
						सीखे बोल सकते हैं वैसे ही आप इंग्लिश भी बिना grammer सीखे बोल सकते
						हैं
					</p>
				</div>
			</div>
		</div>
	);
};

export default WhyBest;
