import "../style/css/Contact.css";
import ContactForm from "./ContactForm";

const Contact = () => {
	return (
		<div id="contact-container">
			<h3 id="contact-label">CONTACT US</h3>
			<div id="contact-form-container">
				<ContactForm />
				<div id="contact-info-container">
					<div id="contact-info-wrapper">
						<div className="contact-info">
							<h3>Support</h3>
							<p>englishbuzz21@gmail.com</p>
						</div>
						<div className="contact-info">
							<h3>Mobile</h3>
							<p>70145 78489</p>
						</div>
						<div className="contact-info">
							<h3>Office</h3>
							<p>76150 78055</p>
						</div>
					</div>
					<div id="map-container">
						<p id="contact-description">
							Address : <br /> No 111/14, Kumbha Marg, Above V-Mart, Sector 11,
							Pratap Nagar, Jaipur, Rajasthan 302011
						</p>
						<h3 id="map-label">LOCATE US</h3>
						<div id="map-wrapper">
							<iframe
								title="Map"
								src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14244.873208473075!2d75.8224473!3d26.8011768!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ae3e0546f8279bb%3A0xb3c8858438eb4975!2sEnglish%20Buzz!5e0!3m2!1sen!2sin!4v1676456297308!5m2!1sen!2sin"
								style={{ border: "0" }}
								allowFullScreen={true}
								loading={"lazy"}
								referrerPolicy={"no-referrer-when-downgrade"}
							></iframe>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Contact;
