import "../style/css/Footer.css";
import Logo from "../img/logo.jpg";
import Insta from "../img/insta.png";
import FB from "../img/facebook.png";
import { Link } from "react-router-dom";

const Footer = () => {
	return (
		<div id="footer-container">
			<div style={{ flex: 2.5 }} className="footer-section">
				<img id="footer-logo" src={Logo} alt="Icon" />
				<p>
					Join India's most unique and creative spoken English classes and take
					your career to new heights.
				</p>
			</div>
			<div style={{ flex: 0.7 }} className="footer-section">
				<h3>LINKS</h3>
				<Link to="contact">Join Now</Link>
				<Link to="contact">Invite Us</Link>
			</div>
			<div style={{ flex: 0.7 }} className="footer-section">
				<h3>ABOUT</h3>
				<Link to="trainers">Trainers</Link>
				<Link to="about">About Us</Link>
			</div>

			<div style={{ flex: 1.5 }} className="footer-section">
				<h3>CONTACTS</h3>
				<h4>
					No 111/14, Kumbha Marg, Above V-Mart, Sector 11, Pratap Nagar, Jaipur,
					Rajasthan 302011
				</h4>
				<h4>englishbuzz21@gmail.com</h4>
				<h4>Mobile: 70145 78489</h4>
				<h4>Office: 76150 78055</h4>
				<div id="footer-social-media-links">
					<a
						href="https://www.facebook.com/Learn.EnglishBuzz/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={FB} alt="Dial logo" />
					</a>
					<a
						href="https://www.instagram.com/_englishbuzz/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={Insta} alt="Dial logo" />
					</a>
				</div>
			</div>
			<div id="copyright-section">
				<p>© Copyright 2023 English Buzz All Right Reserved</p>
				<div>
					<Link to="privacy-policy">Privacy Policy</Link>
					<Link to="terms">Terms & Conditions</Link>
				</div>
			</div>
		</div>
	);
};

export default Footer;
