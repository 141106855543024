import "../style/css/Features.css";
import Interactive from "../img/creative.png";
import Interview from "../img/interview.jpg";
import Fees from "../img/money.jpg";
import WhatsApp from "../img/whatsall.jpg";
import GD from "../img/gd.jpg";
import BodyLanguage from "../img/body_language.jpg";
import LeadershipSkills from "../img/leader_ship_skills.jpg";
import PublicSpeaking from "../img/public_speaking.jpg";
import Personality from "../img/personality_Dev.jpg";
import { useState, useEffect } from "react";
import $ from "jquery";

const Features = () => {
	const features = [
		{
			background: Interactive,
			feature: "Highly Creative & Interactive Classes",
		},
		{
			background: Interview,
			feature: "Interview Training",
		},
		{
			background: WhatsApp,
			feature: "Practice Session on WhatsApp",
		},
		{
			background: Fees,
			feature: "At a most affordable fee",
		},
		{
			background: PublicSpeaking,
			feature: "Public Speaking",
		},
		{
			background: Personality,
			feature: "Personality Development",
		},
		{
			background: GD,
			feature: "GD & Debating Skills",
		},
		{
			background: BodyLanguage,
			feature: "Body Language",
		},
		{
			background: LeadershipSkills,
			feature: "Leadership Skills",
		},
	];

	const [displayIndex, setDisplayIndex] = useState([0, 1, 2, 3]);
	const TIME_MS = 17000;

	const nextFeaturesSlide = () => {
		let i;
		for (let index = 0; index < displayIndex.length; index++) {
			const value = displayIndex[index];
			if (value + 4 > features.length - 1) {
				i = 0;
				break;
			} else {
				i = value + 1;
				break;
			}
		}
		setDisplayIndex([i, i + 1, i + 2, i + 3]);
	};
	const prevFeaturesSlide = () => {
		let i;
		for (let index = 0; index < displayIndex.length; index++) {
			const value = displayIndex[index];
			if (value - 4 < 0) {
				i = features.length - 1;
				break;
			} else {
				i = value - 1;
				break;
			}
		}
		setDisplayIndex([i, i - 1, i - 2, i - 3]);
	};
	useEffect(() => {
		const interval = setInterval(
			() => $("#feature-label").trigger("click"),
			TIME_MS
		);
		return () => clearInterval(interval);
	}, [TIME_MS]);
	return (
		<div id="features-container">
			<h3 id="feature-label" onClick={nextFeaturesSlide}>
				—— FEATURES ——
			</h3>
			<button id="prev-feature-button" onClick={prevFeaturesSlide}>
				❰
			</button>
			<button id="next-feature-button" onClick={nextFeaturesSlide}>
				❱
			</button>
			{features.map((feature, index) => {
				return (
					<div
						className="feature"
						key={index}
						style={{
							backgroundImage: `url(${feature.background})`,
							display: displayIndex.indexOf(index) !== -1 ? "block" : "none",
						}}
					>
						<p>{feature.feature}</p>
					</div>
				);
			})}
		</div>
	);
};

export default Features;
