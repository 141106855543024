import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../style/css/ReadLesson.css";
import { API_URL } from "./util/ApiUtil";
import useAutosizeTextArea from "./util/InputUtil";
import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setLessonCache } from "../features/LessonCache/lessonCache";
import { logout } from "../features/auth/authSlice";
import $ from "jquery";
import { hideProgressbar, showProgressbar } from "./ProgressbarHelper";
const ReadLesson = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	let { id } = useParams();

	const { lessonCache } = useSelector((state) => state.lessonCache);
	const [lesson, setLesson] = useState();
	const [lessonTitle, setLessonTitle] = useState("");
	const [lessonContent, setLessonContent] = useState("");
	const [thumbnail, setThumbnail] = useState("");
	const [timestamp, setTimestamp] = useState("");
	const [isAdmin, setIsAdmin] = useState(false);
	const [contentChanged, setContentChanged] = useState(false);
	const textAreaRef = useRef();
	const titleRef = useRef();

	const { user } = useSelector((state) => state.auth);

	const API_HEADER_CONFIG2 = {
		headers: {
			Authorization: `Bearer ${user ? user.token : ""}`,
		},
	};
	useEffect(() => {
		if (user != null) {
			setIsAdmin(true);
			checkSessionValidity(user.token);
		} else {
			setIsAdmin(false);
		}
	}, [user, dispatch]);
	const checkSessionValidity = async (token) => {
		let data = {
			token: token,
		};
		try {
			const response = await axios.post(API_URL + "session/check", data);
			if (!response) {
				throw {
					response: {
						data: "",
					},
				};
			}
			if (!response.data.isSessionValid) {
				dispatch(logout());
			}
		} catch (error) {
			console.log(error.response.data);
		}
	};
	useEffect(() => {
		if (lessonCache.length < 1) {
			getLesson();
		} else {
			let cachedLesson = lessonCache.filter((item) => item.uid == id);
			if (cachedLesson[0]) {
				setLesson(cachedLesson[0]);
			} else {
				getLesson();
			}
		}
	}, [lessonCache]);

	useEffect(() => {
		if (lesson) populateLesson();
	}, [lesson]);

	useEffect(() => {
		if (lesson) {
			setContentChanged(
				lessonTitle !== lesson.title || lessonContent !== lesson.content
			);
		}
	}, [lessonTitle, lessonContent, lesson]);
	const populateLesson = () => {
		setLessonTitle(lesson.title);
		setLessonContent(lesson.content);
		setThumbnail(lesson.thumbnail);
		setTimestamp(lesson.createdAt);
	};

	const getLesson = async () => {
		let data = {
			id: id,
		};
		try {
			let response = await axios.post(API_URL + "lesson/get", data);
			if (!response) {
				throw {
					response: {
						data: {
							message: "Something went wrong while fetching the lesson",
						},
					},
				};
			}
			if (response.data.snapshot.length < 1)
				navigate("../../lessons", { replace: true });
			else dispatch(setLessonCache(lessonCache.concat(response.data.snapshot)));
		} catch (error) {
			console.log(error);
		}
	};

	useAutosizeTextArea(textAreaRef.current, lessonContent);
	useAutosizeTextArea(titleRef.current, lessonTitle);

	const onDeleteClick = async () => {
		let data = {
			id: id,
		};
		try {
			showProgressbar(".adminActionProgress", "#delete-button");

			let response = await axios.post(
				API_URL + "lesson/delete",
				data,
				API_HEADER_CONFIG2
			);
			if (!response) {
				throw {
					response: {
						data: {
							message: "Something went wrong while deleting lesson",
						},
					},
				};
			}
			let updatedCachedLesson = lessonCache.filter((item) => item.uid !== id);
			dispatch(setLessonCache(updatedCachedLesson));
			submitRefreshRequirement(() => {
				hideProgressbar(".adminActionProgress", "#delete-button");
				navigate("../../lessons", { replace: true });
				alert(response.data.message);
			});
		} catch (error) {
			alert(error.response.data.message);
		}
	};

	const submitLesson = async (e) => {
		e.preventDefault();
		if (lessonTitle.trim() === "") {
			alert("Lesson title should not be empty");
			return;
		}
		if (lessonContent.trim() === "") {
			alert("Lesson content should not be empty");
			return;
		}
		let data = {
			id: id,
			title: lessonTitle.trim(),
			content: lessonContent.trim(),
		};
		try {
			showProgressbar(".adminActionProgress");
			const response = await axios.post(
				API_URL + "lesson/update",
				data,
				API_HEADER_CONFIG2
			);
			if (!response) {
				throw {
					response: {
						data: {
							message: "Something went wrong while uploading lesson",
						},
					},
				};
			}
			let initialLessonCache = lessonCache.filter((item) => item.uid !== id);
			dispatch(
				setLessonCache(initialLessonCache.concat(response.data.snapshot))
			);

			submitRefreshRequirement(() => {
				hideProgressbar(".adminActionProgress");
				alert("Lesson updated successfully");
			});
		} catch (error) {
			hideProgressbar(".adminActionProgress");
			if (error.response.data.message === "sessionexpired") {
				alert("Session expired! please login again");
				dispatch(logout());
			} else {
				alert(error.response.data.message);
			}
		}
	};
	const submitRefreshRequirement = async (callback) => {
		try {
			const response = await axios.post(
				API_URL + "setRefreshRequirement",
				{},
				API_HEADER_CONFIG2
			);
			if (!response) {
				throw {
					response: {
						data: {
							message: "Something went wrong!",
						},
					},
				};
			}
			callback();
		} catch (error) {
			alert(error.response.data.message);
		}
	};
	return (
		<div id="read-lesson-container">
			{/* <div
				style={{ backgroundImage: `url(${thumbnail})` }}
				id="thumbnail"
			></div> */}
			<img src={thumbnail} alt="" />
			{isAdmin && (
				<div id="admin-action-container">
					<button onClick={onDeleteClick} id="delete-button">
						Delete lesson
					</button>
					<button
						onClick={submitLesson}
						disabled={!contentChanged}
						id="edit-button"
					>
						Publish Changes
					</button>
					<div className="progressbar adminActionProgress"></div>
				</div>
			)}

			<textarea
				disabled={!isAdmin}
				id="title"
				value={lessonTitle}
				onChange={(e) => {
					setLessonTitle(e.target.value);
				}}
				ref={titleRef}
				rows={1}
				placeholder="You can't publish empty title"
			></textarea>
			<p id="timestamp">{timestamp}</p>
			<textarea
				disabled={!isAdmin}
				id="content"
				value={lessonContent}
				onChange={(e) => {
					setLessonContent(e.target.value);
				}}
				ref={textAreaRef}
				rows={1}
				placeholder="You can't publish empty article"
			></textarea>
		</div>
	);
};

export default ReadLesson;
