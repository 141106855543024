import "../style/css/Testimonials.css";
import { useEffect, useState } from "react";

import $ from "jquery";
const TestimonialData = [
	{
		image:
			"https://lh3.googleusercontent.com/a/AGNmyxa19GuA-Vy292Q-0vNlL40Xf_QSRNDWfenb31Dy=s40-c-c0x00000000-cc-rp-mo-br100",
		designation: "pranav waychal",
		feedback: `Positive: Communication, Professionalism, Quality, Value
        :
        One of the excellent English coaching Institute from Jaipur. Very interactive sessions with knowledgeable
        faculties. I had a lot of improvement in my English writing As well as speaking skills after attending the
        course. I would like to thanks Harsh sir and the whole English buzz team for their guidance. I would
        suggest this English course to students and working professionals as well.`,
	},
	{
		image:
			"https://lh3.googleusercontent.com/a/AGNmyxZ8uSy-ju_9KDL54xoZyA9JYyadDlyZVhLXiXnr=s40-c-c0x00000000-cc-rp-mo-br100",
		designation: "annu shukla",
		feedback: `This is the best platform where you enhance your communication skills and to remove your hesitation
        and fear of public speaking. Love the Teaching style and friendly environment.
        I recommend to all to join this platform and achieve your goals through this path.`,
	},
	{
		image:
			"https://lh3.googleusercontent.com/a/AGNmyxYBtBPFln6m9Piyz3cMyYn8FroMmE4AXG-MIIlN=s40-c-c0x00000000-cc-rp-mo-ba3-br100",
		designation: "soma saxena",
		feedback: `It was a good opportunity and experience to enroll my daughter in the English Buzz. I felt the difference
        after one month. They give individual attention and participation of every student.`,
	},
	{
		image:
			"https://lh3.googleusercontent.com/a/AGNmyxbzsc_YQmFQFh3amDJfzH2nOnibxWYAicdB5iDO=s40-c-c0x00000000-cc-rp-mo-br100",
		designation: "Loveleena AshokYogi",
		feedback: `This is the best institute where you can learn English and veeru sir is very talented teacher and He solve
        all English problems.I personally suggested to everyone if you have problem in English, you should join
        the institute.`,
	},
	{
		image:
			"https://lh3.googleusercontent.com/a/AGNmyxaxPSvWnJAVyNsRHr5fgjJDfhj2iDwS7mV2rBtZ=s40-c-c0x00000000-cc-rp-mo-br100",
		designation: "Pradnnya Shekhar",
		feedback: `One of the best Spoken English Classes of Jaipur. Harsh Sir and Veeru Sir are the most
        prudent, proficient, committed and wise educators of contemporary time. They do the all round
        development of their pupils.`,
	},
	{
		image:
			"https://lh3.googleusercontent.com/a-/ACB-R5Sd1yPDOv4bsxkyLt9wN2HytTH-Bj_c5CDSim9PEg=s40-c-c0x00000000-cc-rp-mo-br100",
		designation: "Hanuman Prasad",
		feedback: `Best Spoken English classess by Veeru sir. He is the best teacher. He is very friendly and teaches very nice. They provide very good environment for spoken English. They conduct wonderful activities to boost students'confidence. You must join it. Thank you sir for helping me to improve my communication.`,
	},
	{
		image:
			"https://lh3.googleusercontent.com/a/AGNmyxZ8YU58_q6Y1KYWeNaEmWlkQiSenrt4bTAPG2U2=s40-c-c0x00000000-cc-rp-mo-br100",
		designation: "Sanjana Jogi",
		feedback: `This institute helps to gain insight into how you want to build your learning differently. All of this can be done while you learn something new.`,
	},
	{
		image:
			"https://lh3.googleusercontent.com/a/AGNmyxavW9e5bMWDDUNsZ9EXR9BxeUrqXD-4Ue0jQ8gk=s40-c-c0x00000000-cc-rp-mo-ba3-br100",
		designation: "Subodh Badgujar",
		feedback: `Sincerely a good coaching class for english improvement. Nice faculties, and good curriculum. The major difference between this class and others is the importance on practice that is given here. The timely practice sessions conducted here helped me a lot.
        Sincerely recommended.`,
	},

	{
		image:
			"https://lh3.googleusercontent.com/a/AGNmyxb_Iza2qr1cE6OvBKyj5TGeXinLOEuxnDcON09e=s40-c-c0x00000000-cc-rp-mo-br100",
		designation: "RAHUL SANWARIYA",
		feedback: `This is a best place in all Jaipur for learning spoken English, public speaking, personality development, building confidence, career growth, team work this Institute will bring out your best version come and join this amazing Institute.`,
	},
];
const TIME_MS = 17000;
const Testimonials = () => {
	const [selectedIndex, setSelectedIndex] = useState(1);

	const onSlideChange = (offset: number) => {
		if (selectedIndex === 1 && offset === -1)
			setSelectedIndex(TestimonialData.length - 2);
		else if (selectedIndex === TestimonialData.length - 2 && offset === 1)
			setSelectedIndex(1);
		else setSelectedIndex((selectedIndex) => selectedIndex + offset);
	};
	useEffect(() => {
		const interval = setInterval(() => {
			$("#next-button").trigger("click");
			$("#next-feedback-button").trigger("click");
		}, TIME_MS);
		return () => clearInterval(interval);
	}, [TIME_MS]);
	const onNextClick = () => {
		onSlideChange(-1);
	};
	const onPrevClick = () => {
		onSlideChange(1);
	};
	return (
		<div id="testimonial-container">
			<h2 id="testimonials-label">—— WHAT STUDENTS SAY ABOUT US ——</h2>
			<button id="prev-feedback-button" onClick={onNextClick}>
				❰
			</button>
			<button id="next-feedback-button" onClick={onPrevClick}>
				❱
			</button>
			{TestimonialData.map((testimonial, index) => {
				return (
					<div
						key={index}
						style={{
							display:
								index === selectedIndex - 1 ||
								index === selectedIndex ||
								index === selectedIndex + 1
									? "flex"
									: "none",
							flex: index === selectedIndex ? "1.1" : "1",
						}}
						className="testimonial"
					>
						<div className="student-pic-container">
							<div
								style={{ backgroundImage: `url(${testimonial.image})` }}
							></div>
							<p>{testimonial.designation}</p>
						</div>
						<p className="feedback">{testimonial.feedback}</p>
					</div>
				);
			})}
			<div className="primary-divider">
				<div className="primary-divider-wrapper"></div>
			</div>
		</div>
	);
};
export default Testimonials;
