import "../style/css/Home.css";
import Features from "./Features";
import FullHeader from "./FullHeader";
import Courses from "./Courses";
import Testimonials from "./Testimonials";
import Collaboration from "./Collaboration";
import JoinUs from "./JoinUs";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { API_URL } from "./util/ApiUtil";
import axios from "axios";
import { logout } from "../features/auth/authSlice";
import Gallery from "./Gallery";
import FullHeaderMain from "./FullHeaderMain";
import WhyBest from "./WhyBest";
const Home = () => {
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);

	useEffect(() => {
		if (user != null) {
			checkSessionValidity(user.token);
		}
	}, [user, dispatch]);
	const checkSessionValidity = async (token) => {
		let data = {
			token: token,
		};
		try {
			const response = await axios.post(API_URL + "session/check", data);
			if (!response) {
				throw {
					response: {
						data: "",
					},
				};
			}
			if (!response.data.isSessionValid) {
				dispatch(logout());
			}
		} catch (error) {
			console.log(error.response.data);
		}
	};
	return (
		<div id="home-container">
			<FullHeaderMain />
			<Features />
			<WhyBest />
			<Courses />
			<FullHeader />
			<Testimonials />
			<Collaboration />
			<JoinUs />
		</div>
	);
};

export default Home;
