import { Link } from "react-router-dom";
import "../style/css/JoinUs.css";

const JoinUs = () => {
	return (
		<div id="joinus-container">
			<h2>What are you waiting for?</h2>
			<h3>
				BE THE PART OF ENGLISH BUZZ AND FEEL THE BRILLIANCE AND GET A
				SKILL FOR LIFETIME !
			</h3>
			<div>
				<Link id="contact-button-join" to="contact">
					CONTACT NOW
				</Link>
			</div>
		</div>
	);
};
export default JoinUs;
