import "../style/css/About.css";

const About = () => {
	return (
		<div id="about-container">
			<h3 id="about-label">About Us</h3>
			<div className="primary-divider">
				<div className="primary-divider-wrapper"></div>
			</div>
			<div id="about-us">
				<div id="about-us-detail">
					<h3>Who we are</h3>
					<p>
						English Buzz is the only institute of its kind where students are
						trained to be leaders in both their personal and professional lives
						in addition to learning the fundamentals of English Speaking. We
						provide the most cultivating and creative atmosphere to advance your
						English & Public Speaking Skills. At English Buzz, we believe that
						communication is an art that not only helps one to build a great
						career but also strong relationships. With exhaustive research, we
						have come up with many creative & engaging fun activities that will
						help you learn the skills required to become a great communicator
						and speaker. Each session is carefully designed with many effective,
						energetic, and filled activities, including role-playing, group
						discussion, interview training, and much more. All these activities
						will help you with Fluency in speaking English, Public Speaking and
						Personality Development. <br /> <br /> Join today & get a skill for
						a lifetime.
					</p>
				</div>
				<div
					id="about-us-graphic"
					style={{
						backgroundImage: `url(https://s3.eu-central-1.amazonaws.com/fs1.podbean.eu/site/www-v2/images/start/about-us-banner@2x_resize_1x.jpg)`,
					}}
				></div>
			</div>
		</div>
	);
};

export default About;
