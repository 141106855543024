import "../style/css/FullHeaderMain.css";
import Image from "../img/site.png";
import { Link } from "react-router-dom";
const FullHeaderMain = () => {
	return (
		<div id="full-header-main-container">
			<div id="fh-left">

				<h2>Learn Spoken English and Personality Development.</h2>

				<p>
					Join India's most unique and creative spoken English classes and take
					your career to new heights. <br />
					Join now and get a skill for lifetime.
				</p>
				<Link to={"contact"}>Contact Us</Link>
			</div>
			<div id="fh-right">
				<img src={Image} alt="Institute image" />
			</div>
		</div>
	);
};

export default FullHeaderMain;
