import "../style/css/FullHeader.css";
import Logo from "../img/logo_white.png";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { API_URL } from "./util/ApiUtil";
import { useSelector } from "react-redux";
import axios from "axios";
import { useDispatch } from "react-redux";
import { logout } from "../features/auth/authSlice";

const FullHeader = () => {
	const dispatch = useDispatch();
	const [slideIndex, setSlideIndex] = useState(0);
	const [graphics, setGraphics] = useState([]);
	const [isAdmin, setIsAdmin] = useState(false);

	const { user } = useSelector((state) => state.auth);
	useEffect(() => {
		if (user != null) {
			setIsAdmin(true);
			checkSessionValidity(user.token);
		} else {
			setIsAdmin(false);
		}
	}, [user, dispatch]);
	const checkSessionValidity = async (token) => {
		let data = {
			token: token,
		};
		try {
			const response = await axios.post(API_URL + "session/check", data);
			if (!response) {
				throw {
					message: "Something went wrong",
				};
			}
			if (!response.data.isSessionValid) {
				dispatch(logout());
			}
		} catch (error) {
			console.log(error.message);
		}
	};
	const onSlideButtonClick = (offset) => {
		if (slideIndex === 0 && offset === -1) setSlideIndex(graphics.length - 1);
		else if (slideIndex === graphics.length - 1 && offset === 1)
			setSlideIndex(0);
		else setSlideIndex(slideIndex + offset);
	};
	const API_HEADER_CONFIG1 = {
		headers: {
			Authorization: `Bearer ${user ? user.token : ""}`,
			"Content-Type": "multipart/form-data",
		},
	};
	const API_HEADER_CONFIG2 = {
		headers: {
			Authorization: `Bearer ${user ? user.token : ""}`,
		},
	};

	const onNewClick = async (e) => {
		e.preventDefault();
		let graphic = $("#graphic-input").prop("files")[0];
		if (!graphic) {
			alert("Please choose a picture");
			return;
		}
		let formData = new FormData();
		formData.append("graphic", graphic, Date.now());
		try {
			let response = await axios.post(
				API_URL + "graphic/add",
				formData,
				API_HEADER_CONFIG1
			);
			if (!response) {
				throw {
					message: "Something went wrong while adding graphic",
				};
			}
			alert(response.data.message);
			getGraphics();
		} catch (error) {
			alert("Graphic upload error : " + error.message);
		}
	};
	const onDeleteClick = async () => {
		let data = {
			url: graphics[slideIndex],
		};
		try {
			let response = await axios.post(
				API_URL + "graphic/delete",
				data,
				API_HEADER_CONFIG2
			);
			if (!response) {
				throw {
					message: "Something went wrong while deleting graphic",
				};
			}
			alert(response.data.message);
			getGraphics();
		} catch (error) {
			alert("Graphic delete error : " + error.message);
		}
	};
	const getGraphics = async () => {
		try {
			let response = await axios.post(API_URL + "graphic/get");
			if (!response) {
				throw {
					message: "Something went wrong while fetching graphics",
				};
			}
			let urls = [];
			response.data.snapshot.map((item, index) => {
				urls.push(item.url);
			});
			setGraphics(urls);
		} catch (error) {
			console.log("Graphic error : " + error.message);
		}
	};
	useEffect(() => {
		getGraphics();
		// let slideHeight = $("#slider-container").height();
		// let slideWidth = 1.78 * slideHeight;
		// if ($(document).width() > slideWidth)
		// 	$(".responsive-width-limiter").width(slideWidth);
	}, []);

	return (
		<div id="fullheader-container">
			<div
				id="slider-container"
				style={{
					backgroundImage: `url(${graphics[slideIndex]})`,
				}}
			>
				<button id="prev-button" onClick={() => onSlideButtonClick(-1)}>
					❰
				</button>
				<button id="next-button" onClick={() => onSlideButtonClick(1)}>
					❱
				</button>

				{isAdmin && (
					<div id="admin-graphic-action">
						<input type="file" name="graphic-input" id="graphic-input" />
						<button id="new-graphic" onClick={onNewClick}>
							New
						</button>
						<button id="delete-graphic" onClick={onDeleteClick}>
							Delete
						</button>
					</div>
				)}

				<div id="slide-indicator-container">
					{graphics.map((_, index) => {
						return (
							<div
								key={index}
								onClick={() => setSlideIndex(index)}
								className="slide-indicator"
								style={{
									background: slideIndex === index ? "#fff" : "#c9c9c9",
								}}
							></div>
						);
					})}
				</div>
			</div>
			{/* <div id="fullheader-hero-content-container">
				<h2>Learn spoken English with us</h2>
				<p>
					Join Jaipur's most innovative & unique spoken English & Personality
					development classes
				</p>
				<Link id="contact-button" to="contact">
					CONTACT US NOW
				</Link>
				<p>— or —</p>
				<a href="#">Get quote to enroll offline</a>
				<p>Sector 11, Pratap Nagar, Jaipur, Rajasthan 302011</p>
			</div> */}
		</div>
	);
};

export default FullHeader;
